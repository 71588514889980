import React, { useState } from 'react'
import Chance from 'chance'
import Textarea from '../components/Textarea'

const LOWER_LETTERS = `abcdefghijklmnopqrstuvwxyz`
const UPPER_LETTERS = `ABCDEFGHIJKLMNOPQRSTUVWXYZ`
const NUMBERS = `0123456789`
const PUNCTUATIONS = `.,;:!?~+=-_@#$%^&*\/|`
const BRACKETS = `(){}[]<>`
const SPACES = ` `

const PasswordGenerator = () => {

	const [password, setPassword] = useState('')
	
	const [len, setLen] = useState(16)
	
    const [includeLowerLetters, setIncludeLowerLetters] = useState(true)
    const [includeUpperLetters, setIncludeUpperLetters] = useState(true)
    const [includeNumbers, setIncludeNumbers] = useState(true)
    const [includePunctuations, setIncludePunctuations] = useState(true)
    const [includeBrackets, setIncludeBrackets] = useState(false)
    const [includeSpaces, setIncludeSpaces] = useState(false)

    const generate = e => {
		e.preventDefault()
		
		const chance = new Chance()
		let vocabulary = ``

		if (includeLowerLetters) vocabulary += LOWER_LETTERS
		if (includeUpperLetters) vocabulary += UPPER_LETTERS
		if (includeNumbers) vocabulary += NUMBERS
		if (includePunctuations) vocabulary += PUNCTUATIONS
		if (includeBrackets) vocabulary += BRACKETS
		if (includeSpaces) vocabulary += SPACES

		let pass = chance.string({
			pool: vocabulary,
			length: len
		})

        setPassword(pass)
    }

    return (
		<div className="columns is-desktop">
			<form className="column" onSubmit={generate}>

				<div className="field">
					<label className="label" htmlFor="len">
						Password Length
					</label>
					<div className="control">
						<input
							className="input"
							type="number"
							id="len"
							min="1"
							max="100"
							placeholder="1"
							value={String(len)}
							onChange={e => setLen(parseInt(e.target.value))}
						/>
					</div>
				</div>

				<div className="field">
					<div className="control">

						<label className="checkbox">
							<input
								type="checkbox"
								checked={includeLowerLetters}
								onChange={e => setIncludeLowerLetters(Boolean(e.target.checked))}
							/>{` `}
							{LOWER_LETTERS}
						</label>

					</div>
				</div>

				<div className="field">
					<div className="control">
						<label className="checkbox">
							<input
								type="checkbox"
								checked={includeUpperLetters}
								onChange={e => setIncludeUpperLetters(Boolean(e.target.checked))}
							/>{` `}
							{UPPER_LETTERS}
						</label>
					</div>
				</div>

				<div className="field">
					<div className="control">
						<label className="checkbox">
							<input
								type="checkbox"
								checked={includeNumbers}
								onChange={e => setIncludeNumbers(Boolean(e.target.checked))}
							/>{` `}
							{NUMBERS}
						</label>
					</div>
				</div>

				<div className="field">
					<div className="control">
						<label className="checkbox">
							<input
								type="checkbox"
								checked={includePunctuations}
								onChange={e => setIncludePunctuations(Boolean(e.target.checked))}
							/>{` `}
							{PUNCTUATIONS}
						</label>
					</div>
				</div>

				<div className="field">
					<div className="control">
						<label className="checkbox">
							<input
								type="checkbox"
								checked={includeBrackets}
								onChange={e => setIncludeBrackets(Boolean(e.target.checked))}
							/>{` `}
							{BRACKETS}
						</label>
						</div>
				</div>

				<div className="field">
					<div className="control">
						<label className="checkbox">
							<input
								type="checkbox"
								checked={includeSpaces}
								onChange={e => setIncludeSpaces(Boolean(e.target.checked))}
							/>{` `}
							allow spaces <small><em>(e.g. 'heS A-2;aP')</em></small>
						</label>
					</div>
				</div>

				<div>
					<button
						type="submit"
						className="button is-primary"
					>
						Generate
					</button>
				</div>
			
			</form>

			<div className="column is-four-fifths">
				<Textarea
					className="textarea"
					style={{
						width: `100%`,
						backgroundColor: `#fff`,
						padding: `1rem`,
						fontFamily: `monospace`,
					}}
					rows={1}
					value={password}
					showCopyToClipboard={true}
					readOnly={true}
				/>
			</div>
		</div>
    )
}

PasswordGenerator.propTypes = {}

PasswordGenerator.defaultProps = {}

export default PasswordGenerator
