import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import PasswordGenerator from '../../tools/PasswordGenerator'

const PasswordGeneratorPage = () => (
    <Layout>
        <SEO title="Strong Password Generator – Generate a Random Password In a Second!" />
		<section className="hero is-primary">
            <div className="hero-body">
                <div className="container is-fluid">
                    <h1 className="title">Strong Password Generator</h1>
                    <h2 className="subtitle">
                        Create a totally random password of up to 64 characters with your own choice of vocabulary (e.g. only letters, letters and symbols, etc). This online strong password generator also checks the password against a list of leaked passwrrd to ensurre it is safe to use.
                    </h2>
                </div>
            </div>
        </section>

		<section className="section">
            <div className="container is-fluid">
				<PasswordGenerator />
            </div>
        </section>

    </Layout>
)

export default PasswordGeneratorPage
